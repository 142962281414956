<template>
  <div class="form-callback-page">
    <iframe style="width: 100%; height: 500px;" :src="src" />
  </div>
</template>

<script>
export default {
  name: "FormCallback",
  data () {
    return {
      src: process.env.VUE_APP_FORM_CALLBACK_IFRAME_URL
    }
  }
};
</script>

<style scoped>

</style>
