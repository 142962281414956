var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-callback-page" }, [
    _c("iframe", {
      staticStyle: { width: "100%", height: "500px" },
      attrs: { src: _vm.src },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }